import { useState } from 'react';
import OwnershipTag from './OwnershipTag';
import { setUseFilters } from '../store/features/searchSlice';
import { setTasks, Task } from '../store/features/taskSlice';
import {
  setActionOnAccept,
  setModalMessage,
  setModalTask,
  toggleModalShowing,
} from '../store/features/TransactionModal';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { store } from '../store/store';
const ListTaskComponent = (props: { task: Task }) => {
  const [hoveringIgnore, toggleHoveringIgnore] = useState(false),
    dispatch = useAppDispatch(),
    baseUrl = store.getState().user.baseUrl,
    accessToken = store.getState().user.accessToken,
    done = useAppSelector((state) => state.search.done),
    count = useAppSelector((state) => state.search.count),
    ignore = useAppSelector((state) => state.search.ignore),
    useFilters = useAppSelector((state) => state.search.useFilters);

  const getTasks = async () => {
    console.log('getting tasks');
    const accessToken = store.getState().user.accessToken,
      baseUrl = store.getState().user.baseUrl,
      done = store.getState().search.done,
      count = store.getState().search.count,
      ignore = store.getState().search.ignore,
      useFilters = store.getState().search.useFilters;

    if (!accessToken) return;
    const url = baseUrl + '/task/getAll';
    const bodyParams = {
      filter: {
        done: done,
        count: count,
        ignore: ignore,
      },
    };

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
      body: useFilters ? JSON.stringify(bodyParams) : undefined,
    })
      .then((response) => {
        response.json().then((respJson) => {
          console.log('Changelog', respJson);
          const tasks = respJson.tasks;
          store.dispatch(setTasks(tasks));
        });
      })
      .catch((e) => {
        console.info('err', e);
      });
  };
  return (
    <div
      style={{
        display: 'flex',
        margin: 5,
        alignItems: 'center',
        padding: 10,
        justifyContent: 'flex-start',
        gap: 10,
      }}
    >
      <input
        type="checkbox"
        onChange={async () => {
          if (props.task.done) {
            dispatch(toggleModalShowing());
            dispatch(setModalTask(props.task));
            dispatch(
              setModalMessage(`
	Do you really want to mark
	   "${props.task.referenceId}"
	as ${props.task.done ? 'NOT' : ''} done ? `),
            );
            dispatch(setActionOnAccept('done'));
          } else {
            console.log('DONE');
            const apiKey = store.getState().user.accessToken;
            const baseUrl = store.getState().user.baseUrl;
            const task = props.task;

            if (!apiKey || !task) return;
            let markAsDoneRequest = await fetch(baseUrl + '/task/done', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + accessToken,
              },
              body: JSON.stringify({ taskId: task.taskId, status: !task.done }),
            });
            let respJson = await markAsDoneRequest.json();
            console.log('sent mark as done response', respJson);

            if (respJson.errorCode === 0) {
              getTasks();
            }
          }
        }}
        checked={!!props.task.done}
      />

      <div
        style={{
          minWidth: 550,
          maxWidth: 550,
          overflow: 'hidden',
          wordWrap: 'break-word',
        }}
        onClick={() => {
          console.info('setting modal task');
          dispatch(setModalTask(props.task));
        }}
      >
        {' '}
        {props.task.text}{' '}
      </div>
      <div style={{ minWidth: 75, textAlign: 'center', maxWidth: 75 }}>
        {' '}
        ({props.task.count})
      </div>
      <div style={{ minWidth: 300, textAlign: 'center' }}>
        {props.task.done?.date
          ? new Date(props.task.done.date).getHours() +
            ':' +
            new Date(props.task.done.date).getMinutes() +
            '  ' +
            new Date(props.task.done.date).getDate() +
            '.' +
            new Date(props.task.done.date).getMonth() +
            '  by  ' +
            props.task.done.by
          : ''}
      </div>
      <div style={{ minWidth: 115 }}>
        <OwnershipTag cardOwner={props.task.product} />
      </div>
      <div style={{ minWidth: 125, textAlign: 'center' }}>
        {' '}
        {props.task.department}
      </div>

      <div style={{ minWidth: 135, textAlign: 'center', whiteSpace: 'pre' }}>
        {' '}
        {props.task.created
          ? new Date(props.task.created).toLocaleString('de-DE', {
              hour: '2-digit',
              minute: '2-digit',
            }) +
            '  ' +
            new Date(props.task.created).toLocaleString('de-DE', {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
            })
          : ''}
      </div>
      <div style={{ minWidth: 125, textAlign: 'center', whiteSpace: 'pre' }}>
        {props.task.changed
          ? new Date(props.task.changed).toLocaleString('de-DE', {
              hour: '2-digit',
              minute: '2-digit',
            }) +
            '   ' +
            new Date(props.task.changed).toLocaleString('de-DE', {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
            })
          : ''}
      </div>
      <div style={{ minWidth: 125, textAlign: 'center' }}>
        <a
          href={'https://jaeger.comvalue.com/trace/' + props.task.jaegerTraceId}
        >
          {props.task.jaegerTraceId
            ? props.task.jaegerTraceId.split(':')[0]
            : ''}
        </a>
      </div>
      <div style={{ minWidth: 150, textAlign: 'center' }}>
        {props.task.serviceName || ''}
      </div>
      <div style={{ minWidth: 150, textAlign: 'center' }}>
        {props.task.command || ''}
      </div>
    </div>
  );
};
export default ListTaskComponent;
