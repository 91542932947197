import { CSSProperties } from 'react';

const styles: {
  appContainer: CSSProperties;
  sideBarContainer: CSSProperties;
  sidebarButton: CSSProperties;
  loginPage: {
    textInput: CSSProperties;
    submitButton: CSSProperties;
    container: CSSProperties;
    backgroundDiv: CSSProperties;
  };
  landingPage: {
    cardContainer: CSSProperties;
    listContainer: CSSProperties;
  };
  filterBar: {
    container: CSSProperties;
    inputContainer: CSSProperties;
    sortByContainer: CSSProperties;
  };
  bodyContainer: CSSProperties;
} = {
  appContainer: {
    display: 'flex',
    height: '100vh',
    width: '100vw',
    justifyContent: 'center',
    alignItems: 'center',
    // flexDirection: "column",
  },
  sideBarContainer: {
    height: '100vh',
    width: '20vw',
    display: 'flex',
    flexDirection: 'column',
    background: '#25293C',
    color: 'white',
  },
  sidebarButton: {
    padding: 5,
    margin: 'auto',
    width: '90%',
    background: 'white',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: '#00AEF0',
    border: 'none',
    cursor: 'pointer',
    fontSize: 18,
    fontWeight: 600,
    borderRadius: 5,
  },
  loginPage: {
    textInput: {
      fontSize: 18,
      maxWidth: '85%',
    },
    submitButton: {
      margin: 'auto',
      marginTop: 20,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 5,
      paddingTop: 50,
      position: 'relative',
      paddingLeft: 25,
      paddingRight: 25,
      paddingBottom: 25,
      width: 300,
      left: 200,
    },
    backgroundDiv: {
      background: '#25293C',
      position: 'absolute',
      zIndex: -1,
      opacity: 0.7,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
  },
  landingPage: {
    cardContainer: { display: 'flex', flexWrap: 'wrap', padding: 20 },
    listContainer: {
      // display: "flex",
      flexDirection: 'column',
      // borderBottom: "solid 1px",
      height: '88.5vh',
      width: '92.5vw',
    },
  },
  bodyContainer: {
    height: '100vh',
    width: '80vw',
    position: 'relative',
    // overflow: "hidden",
    overflowY: 'auto',
  },
  filterBar: {
    container: {
      position: 'fixed',
      height: '7.5vh',
      left: '19vw',
      right: 0,
      background: '#25293C',
      color: 'white',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      gap: 5,
      paddingLeft: 15,
      alignItems: 'center',
    },
    sortByContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: 250,
      marginTop: 5,
      cursor: 'pointer',
    },
    inputContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: 200,
      marginTop: 5,
      cursor: 'pointer',
    },
  },
};

export default styles;
