import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setAccessToken } from '../../store/features/userSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { store } from '../../store/store';
import styles from '../../styles';

const LoginPage = () => {
  const navigate = useNavigate(),
    dispatch = useAppDispatch(),
    accessToken = useAppSelector((state) => state.user.accessToken),
    [seachParams, setSearchParams] = useSearchParams(),
    baseUrl = store.getState().user.baseUrl,
    [loginError, setLoginError] = useState(false);

  const clientId = 'ed134bc309fdb781cf9204377dc97f93';

  const sendLoginData = async (e: React.FormEvent<HTMLFormElement>) => {
    console.error('SUBMITTING LOGIN FORM');
    e.preventDefault();
    window.location.href = baseUrl + '/login?type=csafe';
  };

  useEffect(() => {
    const localhostFetch = fetch(
      'http://127.0.0.1:2999/login?clientId=' + clientId,
      {
        // method: 'get',
        // mode: 'no-cors',
        // headers: { 'Content-Type': 'application/json' },
      },
    ).then(async (resp) => {
      const response = await resp.json();
      if (response.errorCode === 0) {
        dispatch(setAccessToken(response.jwt.accessToken));
        // dispatch(setRe(response.jwt.refreshToken));
      }

      console.info('!!!!!!!resp from localhost', resp, response);
    });

    const nextAccessToken = accessToken || seachParams.get('accessToken');
    if (nextAccessToken) {
      dispatch(setAccessToken(nextAccessToken));
      console.log('checking for jwt', nextAccessToken);
      navigate('/');
    }
  }, [accessToken]);

  return (
    <div style={styles.appContainer}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          sendLoginData(e);
          console.log('navigate to apocentral oauth');
        }}
      >
        <img
          style={{
            zIndex: -2,
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            height: '100vh',
            width: '100vw',
            overflow: 'hidden',
          }}
          alt=""
          src={require('../../assets/Icons/wallpaperflare.com_wallpaper.jpg')}
        />
        <div style={styles.loginPage.container}>
          <h2 style={{ color: 'white', fontSize: 30 }}> msTask Dashboard</h2>
          <div style={styles.loginPage.backgroundDiv}> </div>
          {/*
          <input
            name="user"
            placeholder="Username"
            style={{
              ...styles.loginPage.textInput,
              color: loginError ? 'red' : undefined,
              border: loginError ? 'red solid 2px ' : undefined,
            }}
            type="text"
            onChange={() => {
              if (loginError) {
                setLoginError(false);
              }
            }}
          />
          <input
            style={{
              ...styles.loginPage.textInput,
              color: loginError ? 'red' : undefined,
              outline: loginError ? 'red solid 2px ' : undefined,
            }}
            placeholder="Passwort"
            name="password"
            type="password"
            onChange={() => {
              if (loginError) {
                setLoginError(false);
              }
            }}
          />
	  */}
          <input
            style={styles.loginPage.submitButton}
            value="Apocentral-Login"
            type="submit"
          />
        </div>
      </form>
    </div>
  );
};
export default LoginPage;
