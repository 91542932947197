import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, store } from '../store';

// Define a type for the slice state
export interface UserState {
  baseUrl: string | undefined;
  accessToken?: string;
}

// Define the initial state using that type
const initialState: UserState = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  accessToken: localStorage.getItem('apiKey') || '',
};

export const userSlice = createSlice({
  name: 'user',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setAccessToken: (state: UserState, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
      localStorage.setItem('apiKey', action.payload);
    },
  },
});

export const { setAccessToken } = userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.user.value

export default userSlice.reducer;
