import { createSlice, isFulfilled, PayloadAction } from '@reduxjs/toolkit';
import { useAppDispatch } from '../hooks';
import { store } from '../store';
import { setTasks, Task } from './taskSlice';

const getTasks = async () => {
  const accessToken = store.getState().user.accessToken,
    baseUrl = store.getState().user.baseUrl,
    done = store.getState().search.done,
    count = store.getState().search.count,
    ignore = store.getState().search.ignore,
    useFilters = store.getState().search.useFilters;

  if (!accessToken) return;
  const url = baseUrl + '/task/getAll';
  const bodyParams = {
    filter: {
      done: done,
      count: count,
      ignore: ignore,
    },
  };

  console.log('getting tasks with filters', useFilters, bodyParams);

  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + accessToken,
    },
    body: useFilters ? JSON.stringify(bodyParams) : undefined,
  })
    .then((response) => {
      response.json().then((respJson) => {
        console.log('Changelog', respJson);
        if (respJson.errorCode === 0) {
          const tasks = respJson.tasks;
          store.dispatch(setTasks(tasks));
        }
      });
    })
    .catch((e) => {
      console.info('err', e);
    });
};

const markAsDone = async () => {
    const accessToken = store.getState().user.accessToken;
    const baseUrl = store.getState().user.baseUrl;
    const task = store.getState().transactionModal.modalTask;
    if (!accessToken || !task) return;
    let markAsDoneRequest = await fetch(baseUrl + '/task/done', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
      },
      body: JSON.stringify({ taskId: task.taskId, status: !task.done }),
    });
    store.dispatch(setModalTask(null));
    store.dispatch(toggleModalShowing());
    let respJson = await markAsDoneRequest.json();
    console.log('sent mark as done response', respJson);

    if (respJson.errorCode === 0) {
      getTasks();
    }
  },
  markAsIgnored = async () => {
    const accessToken = store.getState().user.accessToken;
    const baseUrl = store.getState().user.baseUrl;
    const task = store.getState().transactionModal.modalTask;
    if (!accessToken || !task) return;
    let markAsDoneRequest = await fetch(baseUrl + '/task/ignore', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + accessToken,
        apiKey: accessToken,
      },
      body: JSON.stringify({
        taskId: task.taskId,
        status: !task.ignore,
      }),
    });
    store.dispatch(setModalTask(null));
    store.dispatch(toggleModalShowing());
    let respJson = await markAsDoneRequest.json();
    if (respJson.errorCode === 0) {
      getTasks();
    }
    console.log('sent markAsIgnoredReq', respJson);
  };

interface ModalState {
  showing: boolean;
  success?: boolean;
  message?: string;
  onAccept: { markAsDone: any; markAsIgnored: any };
  modalTask?: Task | null;

  actionOnAccept?: 'done' | 'ignore';
}
// Define the initial state using that type
const initialState: ModalState = {
  success: true,
  showing: false,
  onAccept: { markAsDone: markAsDone, markAsIgnored: markAsIgnored },
};
export const searchSlice = createSlice({
  name: 'search',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setModalSuccess: (state, action: PayloadAction<boolean>) => {
      state.success = action.payload;
    },
    toggleModalShowing: (state) => {
      state.showing = !state.showing;
    },
    setModalMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    setActionOnAccept: (state, action: PayloadAction<'ignore' | 'done'>) => {
      if (action.payload === 'ignore') {
        state.actionOnAccept = 'ignore';
      } else if (action.payload === 'done') {
        state.actionOnAccept = 'done';
      }
    },
    setModalTask: (state, action: PayloadAction<Task | null>) => {
      state.modalTask = action.payload;
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
  },
});

export const {
  setModalSuccess,
  setModalMessage,
  toggleModalShowing,
  setModalTask,
  setActionOnAccept,
} = searchSlice.actions;

// Other code such as selectors can use the imported `RootState` type
// export const selectCount = (state: RootState) => state.user.value

export default searchSlice.reducer;
